import React from 'react';
import Layout from '../components/layout';
import Header from '../components/Header';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import data from '../constants/cookie-policy-data';
import { injectIntl } from 'gatsby-plugin-intl';
import PageContainer from '../components/Containers/PageContainer';

const useStyles = makeStyles(theme => ({
  root: {
    color: COLORS.WHITE,
    '& a': {
      padding: '0 5px',
      '&:hover': { color: COLORS.RED },
    },
    '& >p': {
      width: '60%',
      paddingLeft: 20,
      [theme.breakpoints.down('md')]: {
        width: '90%',
        paddingLeft: 10,
      },
    },
  },
  sections: {
    paddingLeft: 50,
    '& li': {
      paddingTop: 35,
      color: COLORS.RED,
      fontSize: FONTS.SIZE.XXM,
      [theme.breakpoints.down('md')]: {
        fontSize: FONTS.SIZE.XM,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: FONTS.SIZE.M,
      },
      '&>*:nth-child(1)': {
        color: COLORS.WHITE,
        maxWidth: 650,
      },
    },
    '& p': {
      color: COLORS.WHITE,
      width: '50%',
      fontSize: FONTS.SIZE.XS,
      paddingTop: 5,
      '& >span': {
        fontStyle: 'italic',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 30,
      '& p': { width: '90%' },
    },
  },
}));

const CookiePolicy = ({ intl }) => {
  const classes = useStyles();
  const { formatMessage } = intl;
  const t = id => formatMessage({ id });

  return (
    <Layout>
      <PageContainer className={classes.root}>
        {/* <Header secondary>{data.title}</Header> */}
        <Header secondary style={{ paddingBottom: 50 }}>
          {t('CookiePolicy.title')}
        </Header>
        <p>{data.introduction}</p>
        <ol className={classes.sections}>
          {data.sections.map(section => (
            <li key={section.title}>
              <Header sub>{section.title}</Header>
              <p>{section.text}</p>
            </li>
          ))}
        </ol>
      </PageContainer>
    </Layout>
  );
};

export default injectIntl(CookiePolicy);
