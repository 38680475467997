import React from 'react';
import { Link } from 'gatsby-plugin-intl';

// TODO: USE intl with array here, migt work like "id": "[\"Item1\", \"Item2\"]"
// https://spectrum.chat/gatsby-js/general/using-gatsby-plugin-intl-with-an-array-of-items~7d484d0a-701b-446a-8d04-97cc94972924
export default {
  title: `INTEGRITETSPOLICY`,
  introduction: `Headzup Sport AB är bundet av den allmänna dataskyddsförordningen GDPR; skydd av personuppgifter för privatpersoner i hela EU. Vi respekterar din integritet och är skyldiga att skydda din personliga information.
  
  Vår integritetspolicy beskriver vår inställning till integritet och hur vi samlar in, använder och skyddar din personliga information. Det anger också dina rättigheter i förhållande till åtkomst till den information vi samlar in om dig.`,
  sections: [
    {
      title: `REGISTRERING AV PERSONUPPGIFTER`,
      text: `När du genomför ett köp sparas din kontaktinformation för att vi ska kunna hantera din beställning och genomföra din leverans. 
      Om du har registrerat dig för vårt nyhetsbrev skickas dessa ut till dig för att informera om våra senaste nyheter, erbjudanden och eventuella events och/eller tävlingar. Du kan när som helst avregistrera dig från våra nyhetsbrev genom att klicka ”avregistrera” längst ner i nyhetsbrevet.`,
    },
    {
      title: `COOKIES`,
      text: `När du besöker vår webbplats kan vi och/eller tredje part placera cookies i din webbläsare för att förbättra och hantera vår webbplats. En cookie är en liten textfil som innehåller information och lagras i datorn hos den som besöker webbplatsen. Cookies används av tekniska skäl för att du som användare skall få en så bra upplevelse som möjligt och för att registrera besöksstatistik. Du kan stänga av användandet av cookies i inställningarna för din webbrowser eller välja att ta bort existerande cookies för vår sida. Vi använder oss av Google Analytics för att förbättra vår service och användarvänlighet, samt för att analysera hur sidan används. Vi använder Google Ads spårningscookie för annonser som levereras av Google Ads och Facebook spårningscookie för annonser som levereras av Facebook. Popupfönstret om cookies som visas på vår webbplats innehåller också en cookie som löper ut efter 100 dagar.
      När du trycker på ”OK” i vårt popupfönster om cookies har du godkänt vår cookiepolicy.      
      `,
    },
    {
      title: `BILDER OCH FOTOGRAFIER PÅ WEBSIDAN OCH I SOCIALA MEDIER`,
      text: `Allt innehåll, grafik, fotografier, videos, logotyper och kod på vår websida ägs uteslutande av Headzup Sport AB.
      Vi är mycket noga när vi publicerar bilder och/eller fotografier av individer på vår websida eller social media. Vår policy är att om en bild identifierar en individ eller om en text namnger en individ måste den individen ha gett sitt godkännande innan vi publicerar.
      Vi fotograferar och ibland filmar vid olika typer av events. Dessa bilder kan bli publicerade på vår websida och/eller på våra kanaler i social media utan godkännande av alla som syns i bilden. Om du vill medverka i ett sådant event men inte vill bli fotograferad, vänligen informera oss i samband med din registrering till eventet så respekterar vi givetvis din önskan. Vår avsikt med bilderna är aldrig att peka ut någon särskild individ eller grupp. Om du upplever att vi har publicerat en bild på dig som du inte känner dig bekväm med, vänlig ta kontakt med oss så tar vi genast bort bilden.
      `,
    },
    {
      title: `SÄKERHET`,
      text: `Vi hanterar all insamlade data om dig på ett ansvarsfullt sätt och delar inte denna information med tredje part. Vi kan dock komma att ge ut dina personuppgifter till leverantörer som till exempel tillhandahåller annonsering och leveranser för vårt företag.`,
    },
    {
      title: `REGISTRERING AV PERSONUPPGIFTER VID ONLINEKÖP`,
      text: (
        <>
          Alla köp av våra produkter görs i nuläget online
          och via betaltjänsten Stripe.
          <br />
          Läs om hur Stripe hanterar dina personuppgifter på
          deras egen hemsida:
          <a
            href="https://stripe.com/en-se/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/en-se/privacy
          </a>
          <br />
          Här kan du läsa om hur Stripe hanterar
          kreditkortsuppgifter:
          <br />
          <a
            href="https://stripe.com/en-se/checkout/legal"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/en-se/checkout/legal
          </a>
        </>
      ),
    },
    {
      title: `TILLGÅNG TILL INFORMATION`,
      text: (
        <>
          Du kan när som helst begära att få tillgång till
          all information som finns lagrad om dig. Vi
          behöver då en skriftlig begäran från dig där du
          uppger namn, adress, e-post och telefonnummer samt
          beskrivning av ditt ärende. Svaret kommer att
          skickas till den av dig angivna e-postadressen. Du
          skickar ditt ärende till
          <a
            href="mailto:info@headzupsport.com"
            target="_blank"
            rel="noreferrer"
          >
            info@headzupsport.com
          </a>
          <br />
          Du har alltid rätt att begära att dina
          personuppgifter raderas från våra register.
        </>
      ),
    },
    {
      title: `NÄR TILLÄMPAS VÅR INTEGRITETSPOLICY?`,
      text: `Vår integritetspolicy gäller för alla våra register och tjänster på vår websida och onlineshop. Vår websida kan dock innehålla länkar till andra websidor, där vår integritetspolicy inte gäller.`,
    },
    {
      title: `KONTAKT`,
      text: (
        <>
          Om du vill ha mer information om våra policys,
          kontakta oss via email till
          <a
            href="mailto:info@headzupsport.com"
            target="_blank"
            rel="noreferrer"
          >
            info@headzupsport.com
          </a>
          eller via post till Headzup Sport AB,
          Ruskvädersgatan 8, 418 34 Göteborg.
        </>
      ),
    },
    {
      title: `ÄNDRINGAR AV DENNA POLICY`,
      text: (
        <>
          Om denna integritetspolicy av någon anledning
          ändras, kommer den nya versionen att finnas på vår
          websida:
          <Link to="/">headzupsport.com</Link>
          <br />
          <br />
          <span>
            Uppdaterades senast den 28:e september 2020
          </span>
        </>
      ),
    },
  ],
};

// export default {
//   title: `PRIVACY POLICY`,
//   introduction: `HeadzUp sports is bound by the General Data
//       Protection Regulation GDPR - protection of personal
//       data for private individuals throughout the EU. We
//       at HeadzUp respect your privacy and are committed to
//       protecting your personal information. Our privacy
//       policy outlines our approach to privacy and how we
//       collect, use and protect your personal information.
//       It also sets out your rights in relation to
//       accessing the personal information we collect and
//       hold about you.`,
//   sections: [
//     {
//       title: `registration of personal data`,
//       text: `When you make an online purchase, your contact information is saved in order to be able to efficiently handle your delivery or other existing services with us.
//         If you have separately agreed on receiving newsletters, we send the newsletters to inform you about latest news, offers and current events. You can always unsubscribe from our newsletters by sending us an email or by unsubscribing from the newsletters by clicking “unsubscribe” at the bottom of the newsletter.`,
//     },
//     {
//       title: `cookie policy`,
//       text: `When you visit our website, we, and/or third parties, may place cookies on your browser to enhance and manage our website. A cookie is a small text file that contains information and is stored on the visitor's computer. Cookies are used for technical reasons in order for you to be able to use the website in the best way and to register visitor statistics. You can turn off the use of cookies in your browser settings, or remove an existing cookie related to our site. we use google analytics cookie to improve our service, usability and to analyze how the website is used. we use google ads tracking cookie for ads delivered by google ads. we also use facebook tracking cookie for ads delivered by facebook. the cookie popup we show on our site also includes a cookie that expires in 100 days.
//         your acceptance of our cookie policy is deemed to occur if you press the "accept cookies" button on our cookie popup. if you do not agree to our cookie policy, please stop using this website immediately.
//         `,
//     },
//     {
//       title: `intellectual property`,
//       text: `All content, graphics, photographs, videos, sound, logos and computer code used in the website is either owned, controlled, or licensed to headzup sports. We reserve all intellectual property rights of content used on this website.`,
//     },
//     {
//       title: `pictures on the website and publication on social media`,
//       text: `We are careful when we publish pictures of individuals on the website or social media. Our policy is that posts that directly identify an individual with name and/or picture may only be made if the person has given their consent to the publication.
//           we often take pictures and in some cases film sequences at the events we organize. these may be published on our website and/or our channels in social media without the consent of everyone seen in the picture. If you want to participate in an event but do not want to be photographed, please inform us in connection with registration or at the event and we will respect your wishes.
//           our idea when we choose to publish an image is that it should fulfill a purpose and not be perceived as offensive or pointing to any individual or group. If you nevertheless believe that we have made a publication of an image or a post that identifies you personally and that you do not feel comfortable with, contact us and we will immediately remove the image both externally and internally.`,
//     },
//     {
//       title: `information security`,
//       text: `We always handle data about you in a reliable way and we do not share this information to third parties. However, we may disclose your personal information to suppliers who, for example, provide it services, media selection or advertising for our business. Recipients who handle personal data on our behalf must always enter into a so-called personal data assistant agreement with us, in order for us to be able to ensure that your data is handled in a correct and secure manner.`,
//     },
//     {
//       title: `collection of data when making a purchase online`,
//       text: `All our purchases are made online and through xx services. xxx write about service standards here...write about credit card standard here...`,
//     },
//     {
//       title: `access to information`,
//       text: (
//         <>
//           You can once a year request to receive all
//           information stored by us via a written request at
//           <a
//             href="mailto:info@headzupsports.se"
//             target="_blank"
//             rel="noreferrer"
//           >
//             info@headzupsports.se
//           </a>
//           or by sending a written request to: adress här..
//           in the request, we want you to state your name,
//           address, e-mail and telephone number in addition
//           to your case. Replies will be sent to your e-mail
//           or population registration address.
//         </>
//       ),
//     },
//     {
//       title: `amendment and deletion of information`,
//       text: `In certain circumstances, you have the right to ask us to erase your personal data, for example where the personal data we collected is no longer necessary for the original purpose, where personal data has become obsolete or where you withdraw your consent. However, this will need to be balanced against other factors. For example, we may not be able comply with your request due to certain legal or regulatory obligations.

//           how do we use your information?
//           we have never and will never pass on your information to third parties. All information you have stored with us is only related to your purchase of th e product and for you to be able to receive newsletters.`,
//     },
//     {
//       title: `when does this privacy policy apply?`,
//       text: `Our privacy policy applies to all our registers and services linked to our websites. Our websites may contain links to other websites. When you choose to visit these, our privacy policy no longer applies.`,
//     },
//     {
//       title: `contact details and additional information`,
//       text: (
//         <>
//           If you would like more information about our
//           approach to privacy, or if you wish to contact us
//           regarding the information set out in this policy,
//           please contact us by email at
//           <a
//             href="mailto:info@headzupsports.se"
//             target="_blank"
//             rel="noreferrer"
//           >
//             info@headzupsports.se
//           </a>
//           or by post at headzup sports,xxx adress här.
//         </>
//       ),
//     },
//     {
//       title: `change to this policy`,
//       text: (
//         <>
//           We may amend this policy from time to time at our
//           discretion. Amended versions will be posted on our
//           website at <Link to="/">headzupsports.se</Link>{' '}
//           privacy policy last updated in august 2020.
//         </>
//       ),
//     },
//   ],
// };
